const weekday = require('dayjs/plugin/weekday')
const localeData = require('dayjs/plugin/localeData')
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
const duration = require("dayjs/plugin/duration");
const dayjs = require("dayjs")
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(quarterOfYear)
dayjs.extend(duration);
const XLSX = require("xlsx");
import axios from "axios"
import { envType, ossUrl } from '../config';
import Vue from "vue";
/* 格式化tree */
export const formatTree = (obj, options = { id, parentId, originId }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return parent[options['parentId']] == (options['originId'] === undefined ? 0 : options['originId'])  //返回顶层，依据实际情况判断这里的返回值
    })
}
/* 格式化tree */
export const formatTree_fix = (obj, options = { id, parentId, originIdList }) => {
    let copyedObj = JSON.parse(JSON.stringify(obj))  //深拷贝源数据
    return copyedObj.filter(parent => {
        let findChildren = copyedObj.filter(child => {
            return parent[options['id']] === child[options['parentId']]
        })
        findChildren.length > 0 ? parent.children = findChildren : parent.children = []
        return options['originIdList'].includes(parent[options['parentId']])
    })
}
/* 获取文件扩展名 */
export const getFileExtname = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof) {
        const extname = fileName.substring(lastIndexof + 1)
        return extname.toLowerCase()
    }
    return ''
}

/* 获取文件图标地址 */
export const getFileIconAddr = (name) => {
    const extname = getFileExtname(name)
    let iconName = ""
    switch (extname) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
            iconName = "img.png"
            break
        case "pdf":
            iconName = "pdf.png"
            break
        case "docx":
        case "doc":
            iconName = "word.png"
            break
        case "ppt":
        case "pptx":
            iconName = "ppt.png"
            break
        case "xlsx":
        case "xls":
            iconName = "excel.png"
            break
        case "mp3":
            iconName = "mp3.png"
            break
        case "txt":
            iconName = "txt.png"
            break
        case "mp4":
            iconName = "mp4.png"
            break
        case "zip":
            iconName = "zip.png"
            break
        default:
            iconName = "unknown.png"
    }
    return require(`@/assets/image/fileicon/${iconName}`)
}
/* 预览文件 */
export const previewFile = (name, url, vm, index = 0, imgList = []) => {
    const extname = getFileExtname(name)
    if (extname == "ppt" || extname == "pptx" || extname == "xls" || extname == "xlsx" || extname == "doc" || extname == "docx" || extname == 'pdf') {
        vm.$FilePreview({
            url,
        })
    } else if (extname == "png" || extname == "jpg" || extname == "jpeg" == extname == "gif") {
        if (imgList.length == 0) {
            imgList = [url]
        }
        vm.$ImagePreview({
            index,
            imgList,
        })
    }
}
/* 获取文件扩展名包含点 */
export const getFileExtnameAll = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof) {
        const extname = fileName.substring(lastIndexof)
        return extname.toLowerCase()
    }
    return ''
}
/* 获取文件名称 不包含扩展名 */
export const getFileName = (fileName) => {
    if (!fileName) {
        return ''
    }
    const lastIndexof = fileName.lastIndexOf('.')
    if (lastIndexof != -1) {
        return fileName.substring(0, lastIndexof)
    }
    return fileName
}
/* 获取文件名称 包含扩展名 */
export const getFileNameAll = (fileName) => {
    if (!fileName) {
        return
    }
    return fileName.split("/").pop()
}

/* 数据导出ecxcel [[111, 222, 333], [444, 555, 666]] */
export const dataToExcel = (data, fileName) => {
    const ws_name = "SheetJS";
    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, fileName);
}

/* table元素导出excel */
export const tableToExcel = (tableDom, fileName) => {
    const wb = XLSX.utils.table_to_book(tableDom, { sheet: "Sheet JS" });
    XLSX.writeFile(wb, fileName);
}

/* 数据统计计算百分比 */
export const getPercent = (a, b) => {
    if (a == 0) {
        return '0%'
    }
    if (b == 0) {
        return '--'
    }
    return (Math.abs(a - b) / b * 100).toFixed(2) + '%'
}
/* 数据统计处理数字3位加逗号 */
export const getThreeNum = (a) => {
    return Number(a).toLocaleString()
}
/* 数据统计获取增加类型 */
export const getIncreaseType = (a, b) => {
    return a - b > 0 ? 1 : -1
}

/* 下载文件 */
export const downloadFile = async (data, fileName) => {
    let url
    if (/^http/i.test(data)) {
        const blob = await getBlob(data)
        url = window.URL.createObjectURL(blob)
    } else if (data.includes("data:")) {
        url = data
    } else {
        url = window.URL.createObjectURL(data)
    }
    if (!fileName) {
        // 浏览器会自己添加后缀名
        fileName = new Date().getTime()
    }
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
}

/* 获取blob */
export const getBlob = (url) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.responseType = 'blob'
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response)
            }
        }
        xhr.onerror = (err) => {
            reject(err)
        }
        xhr.send()
    })
}

/* 手机号码中键4位数*处理 */
export const dealPhoneNum = (phoneNum) => {
    if (!phoneNum) {
        return ''
    }
    return String(phoneNum).replace(/(\d{3})\d+(\d{4})$/, "$1****$2")
}

/* 获取时长 */
export const getTimeTotal = (num, type = 1) => {
    if (num == '-') {
        return '--'
    }
    if (!num) {
        return '0分钟'
    }
    const h = parseInt(num / 60 / 60)
    const m = parseInt(num / 60 % 60)
    const s = num % 60
    if (type == 1) {
        return `${h ? h + '小时' : ''}${m ? m + '分钟' : ''}${s ? s + '秒' : ''}`
    } else if (type == 2) {
        return `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
    }
}


/* 数字小数部分超过两位数就保存两位小数 */
export const numToFixed = (num) => {
    if (!num) {
        return 0
    }
    const str = String(num)
    const index = str.indexOf('.')
    if (index != -1 && str.substring(index).length > 2) {
        return num.toFixed(2)
    }
    return num
}

/* 数字限制 */
export const handleLimitNum = (prop, value, item, isInt) => {
    if (isInt) {
        value = value.replace(/[^\d^\.]+/g, "").replace(/^0+(\d)/, "$1");
    } else {
        value =
            value
                .replace(/。/g, ".")
                .replace(/[^\d^\.]+/g, "")
                .replace(/^0+(\d)/, "$1")
                .replace(/^\./, "0.")
                .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    }
    item[prop] = value
}
/* 计算数组中数字总和 */
export const arrToTotal = (arr) => {
    let sum = 0
    arr.forEach(item => {
        sum += item
    })
    return sum
}
/* 获取数组最大值 */
export const getArrMaxNum = (arr) => {
    if (!arr || arr.length == 0) {
        return 0
    }
    let max = arr[0]
    arr.forEach(n => {
        if (n > max) {
            max = n
        }
    })
    return max
}

// import { getCurWeekDP, getPrevWeekDP, getCurMonthDP, getCurThreeMonthDP } from '@/utils/tool'

/* 获取本周 */
export const getCurWeekDP = () => {
    const date = dayjs().weekday(0)
    if (dayjs().weekday() == 0) {
        const startDate = date.subtract(6, 'day').toDate()
        const endDate = date.toDate()
        return [startDate, endDate]
    } else {
        const startDate = date.add(1, 'day').toDate()
        const endDate = date.add(7, 'day').toDate()
        return [startDate, endDate]
    }

}
/* 获取上周 */
export const getPrevWeekDP = () => {
    const date = dayjs().weekday(0)
    if (dayjs().weekday() == 0) {
        const startDate = date.subtract(13, 'day').toDate()
        const endDate = date.subtract(7, 'day').toDate()
        return [startDate, endDate]
    } else {
        const startDate = date.subtract(6, 'day').toDate()
        const endDate = date.toDate()
        return [startDate, endDate]
    }
}
/* 获取本月 */
export const getCurMonthDP = () => {
    const monthDay = dayjs().daysInMonth()
    const startDate = dayjs().date(1).toDate()
    const endDate = dayjs().date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取上个月 */
export const getPrevMonthDP = () => {
    const monthDay = dayjs().subtract(1, 'month').daysInMonth()
    const startDate = dayjs().subtract(1, 'month').date(1).toDate()
    const endDate = dayjs().subtract(1, 'month').date(monthDay).toDate()
    return [startDate, endDate]
}
/* 本季度 */
export const getQuarterDP = () => {
    const quarter = dayjs().quarter()
    const monthDay = dayjs().month(quarter * 3 - 1).daysInMonth()
    const startDate = dayjs().month((quarter - 1) * 3).date(1).toDate()
    const endDate = dayjs().month(quarter * 3 - 1).date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取当前三个月 */
export const getCurThreeMonthDP = () => {
    const monthDay = dayjs().daysInMonth()
    const startDate = dayjs().subtract(2, 'month').date(1).toDate()
    const endDate = dayjs().date(monthDay).toDate()
    return [startDate, endDate]
}
/* 获取本年 */
export const getCurYearDP = () => {
    const startDate = dayjs().month(0).date(1).toDate()
    const endDate = dayjs().month(11).date(31).toDate()
    return [startDate, endDate]
}

/* 判断是否是移动端 */
export const isMobile = () => {
    const userAgentInfo = navigator.userAgent;
    let flag = false
    if (/(Android|iPhone|SymbianOS|Windows Phone|iPad|iPod)/i.test(userAgentInfo)) {
        flag = true
    }
    return flag
}
/* 手动拨打处理自定义字段 */
export const dealHandCallUserfield = (studentId) => {
    let userfield
    // VUE_APP_SERVER_ENV 1 内部项目 2 书法项目
    // dev_ 本地测试用  test_ 测试用  prod_ 正是用
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (
            process.env.VUE_APP_SERVER_ENV == "local" ||
            process.env.VUE_APP_SERVER_ENV == "dev"
        ) {
            userfield = `dev_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "test") {
            userfield = `test_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "prod") {
            userfield = `prod_${studentId}`;
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (
            process.env.VUE_APP_SERVER_ENV == "local" ||
            process.env.VUE_APP_SERVER_ENV == "dev"
        ) {
            // fs 或者 shufa 标识书法
            userfield = `shufatest_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "test") {
            userfield = `shufatest_${studentId}`;
        } else if (process.env.VUE_APP_SERVER_ENV == "prod") {
            userfield = `shufaprod_${studentId}`;
        }
    }
    return userfield
}
/* 保存任务时处理任务名 */
export const dealSaveCallTaskName = (name) => {
    let str = ""
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        switch (envType) {
            case "local":
            case "dev":
                str = "nd"
                break
            case "test":
                str = "nt"
                break
            case "prod":
                str = "np"
                break
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        switch (envType) {
            case "local":
            case "dev":
                str = "st"
                break
            case "test":
                str = "st"
                break
            case "prod":
                str = "sp"
                break
        }
    }
    return `${str}@@${name}`
}
/* 获取任务时处理任务名 */
/* 内部正式 np 内部测试 nt 内部dev nd   */
/* 书法正式 sp 书法测试 st */
export const dealShowCallTaskName = (name) => {
    if (!name) {
        return ""
    }
    return name.replace(/(np@@|nt@@|nd@@|sp@@|st@@)/g, "")
}
/* 获取任务环境类型 */
export const getCallTaskEnvType = (name) => {
    if (name.includes("np@@")) {
        return 'np'
    } else if (name.includes("nt@@")) {
        return 'nt'
    } else if (name.includes("nd@@")) {
        return "nd"
    } else if (name.includes("sp@@")) {
        return "sp"
    } else if (name.includes("st@@")) {
        return "st"
    } else {
        return ''
    }
}
/* 获取任务关键字前缀 */
export const getCallSearchNamePrefix = () => {
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (envType == 'dev' || envType == 'local') {
            return 'nd@@'
        } else if (envType == 'test') {
            return 'nt@@'
        } else if (envType == 'prod') {
            return 'np@@'
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (envType == 'dev' || envType == 'local') {
            return 'st@@'
        } else if (envType == 'test') {
            return 'st@@'
        } else if (envType == 'prod') {
            return 'sp@@'
        }
    }
}
/* 获取任务是否属于当前环境 */
export const checkCallTaskIsOpt = (callEnvType) => {
    let curCallEnvType
    if (process.env.VUE_APP_SYS_TYPE == 1) {
        if (envType == 'dev' || envType == 'local') {
            curCallEnvType = 'nd'
        } else if (envType == 'test') {
            curCallEnvType = 'nt'
        } else if (envType == 'prod') {
            curCallEnvType = 'np'
        }
    } else if (process.env.VUE_APP_SYS_TYPE == 2) {
        if (envType == 'dev' || envType == 'local') {
            curCallEnvType = 'st'
        } else if (envType == 'test') {
            curCallEnvType = 'st'
        } else if (envType == 'prod') {
            curCallEnvType = 'sp'
        }
    }
    return callEnvType == curCallEnvType
}
/* 系统中的错误信息提交到服务器 */
const errDataList = []
export const apiErrorDataSendServe = (data) => {
    // 这种广告错误，不重复记录
    if (data.type == 'error-link' || data.type == 'error-script') {
        const { msg } = data
        for (let i = 0; i < errDataList.length; i++) {
            if (errDataList[i].msg == msg) {
                return
            }
        }
        errDataList.push(data)
    }
    const saveData = {
        ...data,
        userId: sessionStorage.getItem('userId'),
        username: sessionStorage.getItem('username'),
        name: sessionStorage.getItem('name'),
        curPage: location.href,
    }
    const xhr = new XMLHttpRequest();
    const url = "/v2/api/saveSysErrorData";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            console.log(xhr.responseText);
        }
    };
    xhr.onerror = () => { }
    xhr.send(JSON.stringify(saveData));
}

/* 处理百分比，保留小数数 */
export const getPercentNum = (num, total, decimalsNum = 0) => {
    if (total == 0) {
        return '0.00%'
    }
    return (Math.round((num / total) * 100 * Math.pow(10, decimalsNum)) / Math.pow(10, decimalsNum)).toFixed(decimalsNum) + '%'
}

/* 版本对比v1本地版本v2远程版本 */
export const checkIsUpdate = (v1, v2) => {
    const listv1 = v1.split('.')
    const listv2 = v2.split(".")
    if (listv2[0] - listv1[0] > 0) {
        return true
    }
    if (listv2[1] - listv1[1] > 0) {
        return true
    }
    if (listv2[2] - listv1[2] > 0) {
        return true
    }
    return false
}
/* 检测是否需要更新 */
export const dealUpdate = async (vm) => {
    try {
        const { data: { version } } = await axios.get(`version.json?_t=${new Date().getTime()}`)
        if (checkIsUpdate(process.env.VUE_APP_VERSION, version) && !window.showUpdateTip) {
            window.showUpdateTip = true
            vm.$confirm("有新的版本是否更新? 如果正在打电话，请打完后再刷新页面重新加载！", "提示", {
                closeOnClickModal: false,
                confirmButtonText: "更新",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    location.reload()
                })
                .catch(() => { });
        }
    } catch (err) {
        console.error("checkIsUpdate err: ", err)
    }
}

/* 获取外显号码信息 通过json方式获取外显号码 */
export const apiGetYyyNumInfo = async () => {
    try {
        const { data: { yyyInfo } } = await axios.get(`version.json?_t=${new Date().getTime()}`)
        return yyyInfo
    } catch (err) {
        console.error("checkIsUpdate err: ", err)
    }
}

/* 获取拨打电话音频url */
/* 
    数企
    https://vip.bdsaas.com/bdsaas/phone/downloadRecordingBySessionId.do?sessionId=badu1831957992956411904
    优音云 
    https://ykf_record.uincall.com:4143/ykfRecord?url=%2Frecord%2F2024%2F08%2F14%2F6004800998%2F6004800998_7c1a7abe-eab1-4c7f-ccaf-1da588d877ed_18201511593_6004800998__20240814180030_dr.wav
    点控云 
    https://dkyrecord.obs.cn-north-4.myhuaweicloud.com:443/10037001002/20240714/20240714-174248-10037001002-18133097443-136xxxx6288-17746583914-cti4-1720950167.148065-98560.mp3
*/

export const apiGetCallAudioUrl = async (audioUrl, newFboccRecID) => {
    return new Promise((resolve, reject) => {
        if (newFboccRecID) {
            resolve(`${ossUrl}${newFboccRecID}`)
            return
        }
        // 李锐 和 宋昌
        if (!(sessionStorage.getItem("name") == "bwxx17" || sessionStorage.getItem("userId") == "560")) {
            resolve(audioUrl)
            return
        }
        Vue.prototype.msgInfo("音频获取中，请稍等！")
        let url = audioUrl
        if (/\.(mp3)$/i.test(audioUrl)) {
            return resolve(url)
        }
        axios.get('/v2/api/downProxy', {
            params: {
                fileUrl: audioUrl
            }, responseType: 'blob', timeout: 0
        }).then(response => {
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            url = URL.createObjectURL(blob);
            resolve(url)
        }).catch(err => {
            console.log("apiGetCallAudioUrl err: ", err)
            resolve(url)
        })
    })

}

// 处理音频地址
export const dealAudioUrl = (audioUrl) => {
    if (/^http/.test(audioUrl)) {
        return audioUrl
    }
    return `${ossUrl}${audioUrl}`
}
// 加载js文件
export const loadScript = (src) => {
    // 创建一个新的script标签
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src
    // 当脚本加载完成后执行回调
    script.onload = function () {
        console.log('Script loaded');
        // 脚本加载完成后可以在这里调用脚本中的函数
    };

    // 如果支持错误事件
    script.onerror = function () {
        console.error('Failed to load script');
    };

    // 将script标签插入到DOM中
    document.head.appendChild(script);
}

// 获取分钟数
export const getFloorMinute = (s) => {
    if (!s) {
        return 0
    }
    return Math.floor(s / 60)
}
// 随机生成字符串
export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        // 获取characters中的随机字符
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
}

// 通过毫秒获取时分秒格式
export const getHHMMSSByMs = (ms) => {
    if (!ms) {
        return "00:00:00"
    }
    // 将毫秒转换为秒
    let totalSeconds = Math.floor(ms / 1000);

    // 计算小时、剩余的分钟和剩余的秒
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    // 格式化输出，确保小时、分钟和秒都是两位数
    const pad = num => (num < 10 ? '0' : '') + num;
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

// 计算中考高考
// 实际得判断当前是上半年下半年
export const dealZhongGaoKao = (str) => {
    const year = new Date().getFullYear()
    switch (str) {
        case "初一":
            return `${year + 3}中考`
        case "初二":
            return `${year + 2}中考`
        case "初三":
            return `${year + 1}中考`
        case "高一":
            return `${year + 3}高考`
        case "高二":
            return `${year + 2}高考`
        case "高三":
            return `${year + 1}高考`
    }
    return null
}

/* 格式化时间获取时分秒 */
export const formatDuration = (milliseconds, showHour = false) => {
    if (!milliseconds) {
        return "00:00:00"
    }
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    let formattedTime = '';
    if (hours > 0 || showHour) {
        formattedTime += hours.toString().padStart(2, '0') + ':';
    }
    formattedTime += minutes.toString().padStart(2, '0') + ':';
    formattedTime += seconds.toString().padStart(2, '0');
    return formattedTime;
}
/* 获取入职时长xx年xx月xx天 */
export const getYearMonthDay = (strDate) => {

    const joinDate = dayjs(strDate, 'YYYY-MM-DD')
    if (dayjs().isBefore(joinDate)) {
        return "还未入职"
    }
    const diffAllDay = dayjs().diff(joinDate, 'day')
    console.log("diffAllDay: ", diffAllDay)
    const diffYear = dayjs().diff(joinDate, 'year')
    const diffMonth = dayjs().subtract(diffYear, 'year').diff(joinDate, 'month')
    const diffDay = dayjs().subtract(diffYear, 'year').subtract(diffMonth, 'month').diff(joinDate, 'day')

    if (diffYear && diffMonth && diffDay) {
        return `${diffYear}年${diffMonth}个月零${diffDay}天`
    } else if (diffYear && !diffMonth && diffDay) {
        return `${diffYear}年零${diffDay}天`
    } else if (diffYear && diffMonth && !diffDay) {
        return `${diffYear}年零${diffMonth}个月`
    } else if (diffYear && !diffMonth && !diffDay) {
        return `${diffYear}年整`
    } else if (!diffYear && diffMonth && diffDay) {
        return `${diffMonth}个月零${diffDay}天`
    } else if (!diffYear && !diffMonth && diffDay) {
        return `${diffDay}天`
    } else if (!diffYear && diffMonth && !diffDay) {
        return `整${diffMonth}个月`
    } else {
        return "刚入职"
    }
}

/* 获取手机号码属于哪个运营商 */
/* 1 移动 2 联通 3 电信 4 其他 */
export const getPhoneOperator = (phone) => {
    if (!phone) {
        return null
    }
    const phoneStr = String(phone).substring(0, 3)
    const yiDongList = ["134", "135", "136", "137", "138", "139", "147", "150", "151", "152", "157", "158", "159", "178", "182", "183", "184", "187", "188", "198"]
    const lianTongList = ["130", "131", "132", "145", "155", "156", "166", "171", "172", "174", "175", "176", "185", "186", "195"]
    const dianXinList = ["133", "149", "153", "173", "177", "180", "181", "199"]
    if (yiDongList.includes(phoneStr)) {
        return 1
    } else if (lianTongList.includes(phoneStr)) {
        return 2
    } else if (dianXinList.includes(phoneStr)) {
        return 3
    } else {
        return 4
    }
}
function getAudioDuration(arrayBuffer) {
    return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
            const duration = audioBuffer.duration;
            resolve(duration);
        }, (error) => {
            reject(error);
        });
    });
}
/* 获取录音文件时长 */
export const getAudioFileDuration = async (file) => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const duration = await getAudioDuration(arrayBuffer)
                console.log("duration: ", duration)
                resolve(parseInt(duration))
            };
            reader.onerror = (err) => {
                console.error("getAudioFileDuration err: ", err)
                resolve(0)
            }
            reader.readAsArrayBuffer(file);
        } catch (err) {
            resolve(0)
        }
    })
}
/* 获取视频文件时长 */
export const getVideoFileDuration = async (file) => {
    return new Promise((resolve, reject) => {
        const videoURL = URL.createObjectURL(file); // 创建视频文件的 URL
        const videoElement = document.createElement('video'); // 创建 video 元素

        videoElement.src = videoURL; // 设置视频源

        // 监听元数据加载完成事件
        videoElement.addEventListener('loadedmetadata', function () {
            const duration = videoElement.duration; // 获取视频时长

            resolve(parseInt(duration))
            // 销毁 video 元素
            URL.revokeObjectURL(videoURL); // 释放临时 URL
            videoElement.remove(); // 从 DOM 中移除 video 元素（如果已添加）
            //videoElement.src = ''; // 清空 src
            //videoElement.load(); // 重新加载以释放资源 
        });
        videoElement.addEventListener("error", (err) => {
            console.error("getVideoFileDuration err: ", err)
            reject()
        })
        // 加载视频
        videoElement.load();
    })
}
/* 获取字符串中纯文字有多少个 */
export const getWordNumInString = (str) => {
    // 使用正则表达式匹配中文字符和英文字符
    const textCharacters = str.match(/[\u4e00-\u9fa5a-zA-Z]/g);

    // 如果匹配到文字字符，返回匹配到的数量；否则返回 0
    return textCharacters ? textCharacters.length : 0;
}
/* 获取录音转出来的文字中包含多少个字符 */
export const getAudioTxtNumInString = (str) => {
    if (!str) {
        return 0
    }
    const txtList = str.split("\n");
    let resNum = 0
    txtList.forEach((item) => {
        let index = item.indexOf("：");
        if (index == -1) {
            index = item.indexOf(":")
        }
        let txtValue = "";
        if (index != -1) {
            txtValue = item.substring(index + 1);
        } else {
            txtValue = item;
        }
        resNum += getWordNumInString(txtValue)
    });
    return resNum
}

/* 判断iframe是否出现了滚动条 */
export const iframeHasVerticalScrollbar = (iframe) => {
    const contentDocument = iframe.contentDocument || iframe.contentWindow.document;
    const { height } = iframe.getBoundingClientRect()
    //return contentDocument.documentElement.scrollHeight > contentDocument.documentElement.clientHeight;
    return contentDocument.documentElement.scrollHeight > height
}
/* 判断浏览器默认滚动条的宽度 */
export const getScrollbarDefaultWidth = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    document.body.removeChild(outer);

    return scrollbarWidth;
}
/* 将数字转换未中文表示 */
export const numberToChinese = (num) => {
    const units = ['', '十', '百', '千'];
    const bigUnits = ['', '万', '亿'];
    const chars = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];

    if (num === 0) return chars[0]; // 处理 0

    let str = '';
    let needZero = false;
    let pos = 0; // 单位位置

    while (num > 0) {
        let section = num % 10000; // 每 4 位为一段
        if (needZero) {
            str = chars[0] + str; // 添加零
        }
        let sectionStr = '';
        for (let i = 0; i < 4; i++) {
            const n = section % 10;
            if (n === 0) {
                if (sectionStr !== '' && sectionStr[0] !== chars[0]) {
                    sectionStr = chars[0] + sectionStr;
                }
            } else {
                sectionStr = chars[n] + units[i] + sectionStr;
            }
            section = Math.floor(section / 10);
        }
        if (sectionStr !== '') {
            sectionStr += bigUnits[pos];
        }
        str = sectionStr + str;
        needZero = section < 1000 && section > 0;
        num = Math.floor(num / 10000);
        pos++;
    }
    // 处理 "一十" 开头的特殊情况
    if (str.startsWith('一十')) {
        str = str.slice(1);
    } else if (str.startsWith("零")) {
        str = str.slice(1)
    }
    if (str.startsWith('一十')) {
        str = str.slice(1);
    }
    return str;
}
/* 将数据转为时分格式 */
export const numberToHourMinute = (num) => {
    if (num < 10) {
        return `0${num}:00`
    } else {
        return `${num}:00`
    }
}
/* 获取分钟和秒数 */
export const secondToMinuteAndSecond = (num) => {
    const m = parseInt(num / 60)
    const s = num % 60
    if (m && s) {
        return `${m}分钟${s}秒`
    } else if (m) {
        return `${m}分钟`
    } else if (s) {
        return `${s}秒`
    } else {
        return "0分钟"
    }
}
/* 根据时间戳获取时间持续时间 */
export const getDurationByValueOf = (time, type = 1) => {
    // type 1  只显示时分秒 0不显示
    let result = ""
    if (type == 1) {
        let tempTime = dayjs.duration(time).format("H:m:s")
        const hour = tempTime.split(":")[0]
        const minute = tempTime.split(":")[1]
        const second = tempTime.split(":")[2]
        if (hour > 0 && minute > 0 && second > 0) {
            result = `${hour}小时${minute}分钟${second}秒`
        } else if (hour > 0 && minute == 0 && second == 0) {
            result = `${hour}小时`
        } else if (hour > 0 && minute == 0 && second > 0) {
            result = `${hour}小时${second}秒`
        } else if (hour > 0 && minute > 0 && second == 0) {
            result = `${hour}小时${minute}分钟`
        } else if(hour == 0 && minute > 0 && second > 0) {
            result = `${minute}分钟${second}秒`
        } else if(hour == 0 && minute == 0 && second > 0) {
            result = `${second}秒`
        } else if(hour == 0 && minute > 0 && second == 0) {
            result = `${minute}分钟`
        } else if(hour == 0 && minute == 0 && second == 0) {
            result = `${minute}分钟`
        } 
    }
    return result
}
/* 根据开始时间和结束时间获取时间持续时间 */
export const getDurationByStartAndEnd = (startTime, endTime, type = 1) => {
    // type 1  只显示时分 0不显示
    let result = ""
    const diffDuration = dayjs(endTime).diff(dayjs(startTime))
    if (type == 1) {
        let tempTime = dayjs.duration(diffDuration).format("H:m")
        const hour = tempTime.split(":")[0]
        const minute = tempTime.split(":")[1]
        if (hour > 0 && minute > 0) {
            result = `${hour}小时${minute}分`
        } else if (hour > 0) {
            result = `${hour}小时`
        } else if (minute > 0) {
            result = `${minute}分钟`
        } else {
            result = "0小时"
        }
    }
    return result
}
/* 计算百分比 */
export const getPercentV2 = (num, total) => {
    if(!total || total == '0'){
        return '0%'
    }
    if((!num || num == '0')) {
        return "0%"
    }
    return `${numToFixed(num / total * 100)}%`
}
/* 计算比例 */
export const getNumScale = (num1, num2) => {
    if ((!num2 || num2 == '0') || (!num1 || num1 == '0')) {
        return 0
    }
    return numToFixed(num1 / num2)
}
/* 格式化数字，例如添加逗号 */
export const formatNumberWithCommas = (number, size = 3, strSign = ",") => {
    if (!number) {
        return "0"
    }
    let numStr = number.toString();
    let str = numStr.split(".")[0]
    let decimalsNum = numStr.split(".")[1] ? numStr.split(".")[1] : ""
    let result = '';
    let count = 0;

    for (let i = str.length - 1; i >= 0; i--) {
        result = str[i] + result;
        count++;
        if (count % size === 0 && i !== 0) {
            result = strSign + result;
        }
    }

    return `${result}${decimalsNum ? "." + decimalsNum : ""}`;
}
/* 处理数据格式，获取图表格式数据 */
export const getSaleWorkStatusData = (callList, timeType = 1) => {
    /* callList.unshift({
        tend: "2025-03-26 08:48:35",
        tstart: "2025-03-26 08:44:44"
    }) */
    // timeType 1 休息时间段在9点到18点之间 午休中的时间范围不算在算在休息中  2 休息时间段在9点到18点之间  午休中的时间范围算在算在休息中 3 休息时间段不限制，午休中的时间范围算在休息中
    const resList = []
    let restTotal = 0, restMinute = 0, restTotalMinute = 0
    if (callList.length == 0) {
        return {
            restMinute,
            restTotalMinute,
            restTotal,
            resList,
        }
    }
    callList.sort((a, b) => {
        return dayjs(a.tstart).isAfter(dayjs(b.tstart)) ? 1 : -1
    })
    const dealDay = dayjs(callList[0].tstart).format('YYYY-MM-DD')
    // 几个关键时间点 9 12 13:30 18
    const time_9 = dayjs(`${dealDay} 09:00:00`).valueOf()
    const time_12 = dayjs(`${dealDay} 12:00:00`).valueOf()
    const time_13_30 = dayjs(`${dealDay} 13:30:00`).valueOf()
    const time_18 = dayjs(`${dealDay} 18:00:00`).valueOf()
    //const time_cur = dayjs().subtract(1, 'day').valueOf()
    //const isToday = dayjs().subtract(1, 'day').format('YYYY-MM-DD') == dealDay
    const time_cur = dayjs().valueOf()
    const isToday = dayjs().format('YYYY-MM-DD') == dealDay
    // type 1 休息 2 工作
    const min5ToMilli = 5 * 60 * 1000  // 5分对应的毫秒数
    const min10ToMillli = 10 * 60 * 1000
    const min15ToMillli = 15 * 60 * 1000
    const min30ToMillli = 30 * 60 * 1000
    const min60ToMillli = 60 * 60 * 1000
    let callStart = callList[0].tstart
    const tempList1 = [], tempList2 = []
    for (let i = 0; i < callList.length - 1; i++) {
        const { tstart, tend } = callList[i]
        const { tstart: nextTstart, tend: nextTend } = callList[i + 1]
        if (dayjs(nextTstart).diff(dayjs(tend)) > min5ToMilli) {
            tempList1.push({
                tstart: callStart,
                tend,
                type: 2,
            })
            callStart = nextTstart
        }
        // 到最后一个了
        if (i + 1 == callList.length - 1) {
            tempList1.push({
                tstart: callStart,
                tend: nextTend,
                type: 2,
            })
        }
    }
    for (let i = 0; i < tempList1.length - 1; i++) {
        const { tstart, tend } = tempList1[i]
        const { tstart: nextTstart, tend: nextTend } = tempList1[i + 1]
        const time_start = dayjs(tend).valueOf()
        const time_end = dayjs(nextTstart).valueOf()
        // 工作
        if (i == 0) {
            // 拨打第一个电话大于9点
            if (time_start > time_9) {
                // 相当于09:05后打电话
                if (time_start - time_9 > min5ToMilli) {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: tstart,
                        type: 1,
                    })
                    tempList2.push({
                        tstart,
                        tend,
                        type: 2
                    })
                } else {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend,
                        type: 2
                    })
                }
            } else {
                tempList2.push({
                    tstart,
                    tend,
                    type: 2
                })
            }
        } else {
            tempList2.push({
                tstart,
                tend,
                type: 2
            })
        }
        // 休息
        if (timeType == 1) {
            // 几个关键时间点 9 12 13:30 18
            // 休息结束时间小于9点  休息开始时间大于18点  休息开始时间大于12点结束时间结束时间小于13点半
            if (time_end <= time_9 || time_start >= time_18 || (time_start >= time_12 && time_end <= time_13_30)) {
                // 休息结束时间小于9点，不处理
            } else if (time_start < time_9 && time_end > time_9) {
                // 休息开始时间小于9点，休息结束时间大于9点
                if (time_end > time_18) {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: `${dealDay} 12:00:00`,
                        type: 1,
                        ss: 1,
                    })
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: `${dealDay} 18:00:00`,
                        type: 1,
                    })
                } else if (time_end > time_13_30) {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: `${dealDay} 12:00:00`,
                        type: 1,
                        ss: 2,
                    })
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: nextTstart,
                        type: 1,
                        ss: 7,
                    })
                } else if (time_end > time_12) {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: `${dealDay} 12:00:00`,
                        type: 1,
                        ss: 3,
                    })
                } else {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: nextTstart,
                        type: 1,
                    })
                }
            } else if (time_start < time_12 && time_end > time_12) {
                if (time_end > time_18) {
                    if(time_start + min5ToMilli > time_12) {
                        tempList2[tempList2.length - 1].tend = `${dealDay} 12:00:00`
                    } else {
                        tempList2.push({
                            tstart: tend,
                            tend: `${dealDay} 12:00:00`,
                            type: 1,
                            ss: 4,
                        })
                    }
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: `${dealDay} 18:00:00`,
                        type: 1,
                    })
                } else if (time_end > time_13_30) {
                    if(time_start + min5ToMilli > time_12) {
                        tempList2[tempList2.length - 1].tend = `${dealDay} 12:00:00`
                    } else {
                        tempList2.push({
                            tstart: tend,
                            tend: `${dealDay} 12:00:00`,
                            type: 1,
                            ss: 5,
                        })
                    }
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: nextTstart,
                        type: 1,
                        ss: 8,
                    })
                } else if (time_end > time_12) {
                    if(time_start + min5ToMilli > time_12) {
                        tempList2[tempList2.length - 1].tend = `${dealDay} 12:00:00`
                    } else {
                        tempList2.push({
                            tstart: tend,
                            tend: `${dealDay} 12:00:00`,
                            type: 1,
                            ss: 6,
                        })
                    }
                }
            } else if (time_start < time_13_30 && time_end > time_13_30) {
                if (time_end > time_18) {
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: `${dealDay} 18:00:00`,
                        type: 1,
                    })
                } else {
                    tempList2.push({
                        tstart: `${dealDay} 13:30:00`,
                        tend: nextTstart,
                        type: 1,
                        ss: 9,
                    })
                }
            } else if (time_start < time_18 && time_end > time_18) {
                if(time_start + min5ToMilli > time_18) {
                    tempList2[tempList2.length - 1].tend = `${dealDay} 18:00:00`
                } else {
                    tempList2.push({
                        tstart: tend,
                        tend: `${dealDay} 18:00:00`,
                        type: 1,
                        ss: 12,
                    })
                }
                
            } else {
                tempList2.push({
                    tstart: tend,
                    tend: nextTstart,
                    type: 1,
                })
            }
        } else if (timeType == 2) {
            // 几个关键时间点 9 18
            // 休息结束时间小于9点  休息开始时间大于18点  
            if (time_end <= time_9 || time_start >= time_18) {
                // 休息结束时间小于9点，不处理
            } else if (time_start < time_9 && time_end > time_9) {
                // 休息开始时间小于9点，休息结束时间大于9点
                if (time_end > time_18) {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: `${dealDay} 18:00:00`,
                        type: 1,
                    })
                } else {
                    tempList2.push({
                        tstart: `${dealDay} 09:00:00`,
                        tend: nextTstart,
                        type: 1,
                    })
                }
            } else if (time_start < time_18 && time_end > time_18) {
                tempList2.push({
                    tstart,
                    tend: `${dealDay} 18:00:00`,
                    type: 1,
                    ss: 11,
                })
            } else {
                tempList2.push({
                    tstart: tend,
                    tend: nextTstart,
                    type: 1,
                })
            }
        } else {
            tempList2.push({
                tstart: tend,
                tend: nextTstart,
                type: 1,
            })
        }
        if (i + 1 == tempList1.length - 1) {
            const time_last = dayjs(nextTend).valueOf()
            if (time_last < time_18) {
                if (isToday && time_cur < time_18) {
                    if ((time_last < time_cur) && (time_last + min5ToMilli >= time_cur)) {
                        tempList2.push({
                            tstart: nextTstart,
                            tend: dayjs(time_cur).format("YYYY-MM-DD HH:mm:ss"),
                            type: 2
                        })
                    } else {
                        tempList2.push({
                            tstart: nextTstart,
                            tend: nextTend,
                            type: 2
                        })
                        if (time_last < time_cur) {
                            tempList2.push({
                                tstart: nextTend,
                                tend: dayjs(time_cur).format("YYYY-MM-DD HH:mm:ss"),
                                type: 1
                            })
                        }
                    }
                } else {
                    if (time_last + min5ToMilli >= time_18) {
                        tempList2.push({
                            tstart: nextTstart,
                            tend: `${dealDay} 18:00:00`,
                            type: 2
                        })
                    } else {
                        tempList2.push({
                            tstart: nextTstart,
                            tend: nextTend,
                            type: 2
                        })
                        tempList2.push({
                            tstart: nextTend,
                            tend: `${dealDay} 18:00:00`,
                            type: 1,
                            ss: 10,
                        })
                    }
                }

            } else {
                tempList2.push({
                    tstart: nextTstart,
                    tend: nextTend,
                    type: 2
                })
            }
        }
    }
    // 再遍历一遍，处理异常情况 (可能13:30-13:34 休息)
    for(let i = 0; i < tempList2.length; i++) {
        const { tstart, tend, type } = tempList2[i]
        if(type == 1) {
            const time_start = dayjs(tstart).valueOf()
            const time_end = dayjs(tend).valueOf()
            if(time_start + min5ToMilli > time_end) {
                if(tempList2[i + 1] && tempList2[i + 1].type == 2 && tend == tempList2[i + 1].tstart) {
                    tempList2[i + 1].tstart = tstart
                }
                tempList2.splice(i, 1)
            } 
        }
    } 
    let restTotalMs = 0
    tempList2.forEach(item => {
        const { tstart, tend, type } = item
        let level
        const diffMs = dayjs(tend).diff(dayjs(tstart))
        const durationMinute = parseInt(diffMs / 1000 / 60)
        if (type == 1) {
            restTotalMinute += durationMinute
            restTotalMs += diffMs
            restTotal += 1
            if (diffMs > min15ToMillli) {
                level = 3
            } else if (diffMs > min10ToMillli) {
                level = 2
            } else {
                level = 1
            }
        } else if (type == 2) {
            if (diffMs > min60ToMillli) {
                level = 3
            } else if (diffMs > min30ToMillli) {
                level = 2
            } else {
                level = 1
            }
        }
        resList.push({
            startTime: dayjs(tstart).format("HH:mm"),
            endTime: dayjs(tend).format("HH:mm"),
            type,
            level,
            diffMs,
            durationMinute,
        })
    })
    /* console.log("原始数据 callList: ", callList)
    console.log("通话时长 tempList1: ", tempList1)
    console.log("tempList2: ", tempList2)
    console.log("resList: ", resList)   */
    restMinute = parseInt(restTotalMs / (60 * 1000))
    return {
        restTotal,
        restMinute,
        restTotalMinute,
        resList,
    }
}